import { version, versionNew } from "../config/RoutingURLs";
import EnvConfig from "./env.config.json";

const ApiEndPoints = {
  networkCoverage: `/provsvc/${version}/address/network/coverage`,
  loginWithNumber: `/custsvc/${version}/customers/reachnumber/`,
  resetPassword: `/custsvc/${version}/customer/reset/password/link/email`,
  countryList: `/provsvc/${version}/intl/roaming/countries/`,
  hubspot: `/custsvc/${version}/customers/hubspot`,
  customerDetails: `custsvc/${version}/customers/username?id=`,
  StateCityByZipcode: `/provsvc/${version}/mdn/zipcode/availability/`,
  createCustomer: `/custsvc/${version}/`,
  updateCustomer: `/custsvc/${version}/customers/`,
  taxCalculation: `/taxsvc/${version}/taxes/`,
  updateCustomerSource: `/custsvc/${version}/update/fields/customer/`,
  coupon: `/catalogsvc/${version}/coupon/`,
  couponReserve: `/catalogsvc/${version}/coupon/reserve/promo`,
  eligibility: `/catalogsvc/${version}/coupon/eligibility?emailId=`,
  reachPlan: `/catalogsvc/${version}/reachplans/`,
  checkIMEI: `/provsvc/${version}/imei/`,
  configKeys: `/confsvc/${version}/config/keys/`,
  SIMIdCheck: `/provsvc/${version}/icc/validate/`,
  SIMIDValiate: `/provsvc/${version}/iccid/validate`,
  trackOrderShipment: `/shipsvc/${version}/shipment/order/`,
  activateLine: `/provsvc/${version}/activation`,
  monthInAdvance: `/billsvc/${version}/month/in/advance/amount/`,
  creditCardDetails: `/custsvc/${version}/customer/cc/`,
  monthInAdvancePost: `billsvc/v0/month/in/advance/pay/`,
  topupPulse: `/custsvc/${
    EnvConfig?.OPERATOR?.toUpperCase() === "PLUM_VAD" &&
    EnvConfig?.MULTIPLE_DATA_TOP_UP_REQUIRED
      ? versionNew
      : version
  }/topup/pulse`,
  activationStatusCheck: `/provsvc/v0/activation/status/`,
  tax4Calculation: `/taxsvc/${version}/taxes/usa`,
  configInfo: `/confsvc/${version}/config/info`,
  topUpCustomer: `/custsvc/${version}/topup/customer/`,
  topUpCustomerStatus: `/custsvc/${version}/customer/operations/status/`,
  topUpGroup: `/custsvc/${version}/topup/group/`,
  currentDataUsage: `/cdrsvc/${version}/cdr/data/usage/customer/current/`,
  billCycleDataUsage: `/cdrsvc/${version}/cdr/data/usage/customer/overall/`,
  customerDataUsage: `/cdrsvc/${version}/data/usage/customer/`,
  customerGroup: `/custsvc/${version}/customers/group/`,
  paRoamingPurchasePack: `provsvc/${version}/intl/roaming/pack/purchase/customer/`,
  PaRoamingAvailableOfferings: `catalogsvc/${version}/get/additional/offerings`,
  port: `/provsvc/${version}/port/validate/`,
  portResult: `/provsvc/${version}/port/validate/result/`,
  checkEmailAvailability: `/custsvc/${version}/customers/availability/`,
  secondaryCustomerUpdate: `/custsvc/${version}/customers/secondary/update`,
  changePlan: `/catalogsvc/${version}/reachplan/change`,
  PlanChangeCustomerStatus: `/custsvc/${version}/customer/operations/status/`,
  topUpUpdate: `/provsvc/${version}/auto/topup/update`,
  autoTopUp: `/provsvc/${version}/auto/topup/customer/`,
  paymentHistory: `/billsvc/${version}/billing/customize/invoices`,
  dataCutOff: `/custsvc/${version}/data/cutoff/manage`,
  referralCredits: `/catalogsvc/${version}/coupon/referral/analytics/group/`,
  emailBill: `/custsvc/${version}/customer/send/invoice?emailId=`,
  updateCardExpiry: `/custsvc/${version}/cc/update/`,
  autoPayCreditCard: `/custsvc/${version}/customer/cc/autopay/`,
  removeCreditCard: `/custsvc/${version}/customer/cc/delete/`,
  setDefaultCard: `custsvc/${version}/customer/cc/update/`,
  billing: `/billsvc/${version}/billing/customer`,
  plumBills: `/billsvc/${version}/billing/customize/bills/`,
  referalDetails: `/catalogsvc/${version}/coupon/referral/detail`,
  billingForecast: `/billsvc/${versionNew}/billing/forecast/customer/`,
  customerPlan: `custsvc/${version}/customer/plan`,
  resendEsimKit: `provsvc/${version}/resend/qr/`,
  currentBillPay: `billsvc/${version}/payment/customer`,
  getShipmentById: `/shipsvc/${version}/shipment/order/customer/`,
  getSettings: `/provsvc/${version}/network/settings/`,
  updateLanguage: `/provsvc/${version}/update/voicemail/language/`,
  voicemailResetPassword: `/provsvc/${version}/customer/rest/voicemail/password/`,
  dataSaver: `/provsvc/${version}/sim/settings/dataSaver/`,
  wifiUpdate: `/provsvc/${version}/wificalling/update/status/`,
  internationalRoming: `/provsvc/${version}/intl/roaming/plans/IR`,
  internationalCalling: `/provsvc/${version}/intl/roaming/plans/ILD`,
  walletPurchaseHistory: `/custsvc/${version}/customer/purchase/credit/history/`,
  walletPurchase: `/custsvc/${version}/customer/purchase/credit`,
  updateRoaming: `provsvc/${version}/intl/roaming/update`,
  eligibilityCheckApi: `/wowsvc/fetch/fetchDiscountCode`,
  hsdAddApi: `/wowutilssvc/v0/hsd/discount`,
  discountAppliedApi: `wowutilssvc/v0/hsd/discount/applied/`,
  landingPromo: `catalogsvc/${version}/reachplans/`,
  reservePromoCoupon: `/catalogsvc/${version}/coupon/email/mapping`,
  checkCompatibility: `/provsvc/${version}/imei/info/`,
  appDataApi: `cfgssvc/${version}/app/data/`,
  getAllPacks: `/provsvc/${version}/intl/roaming/pack/group/`,
  irAutoRenew: `/provsvc/${version}/intl/roaming/pack/`,
  irDataAddOn: `provsvc/${version}/intl/roaming/pack/`,
  irGetAvailOfferingId: `catalogsvc/${version}/additional/offerings/`,
  deleteFuturePack: `/provsvc/${version}/intl/roaming/pack/`,
  rescheduleFuturePack: `/provsvc/${version}/intl/roaming/pack/`,
  getPackUsageHistory: `/provsvc/${version}/intl/roaming/pack/`,
  getTransactionHistory: `custsvc/v0/customer/purchase/credit/history/`,
  getILDCredits: `/provsvc/${version}/ild/status/group/`,
  getILDUsageHistory: `/provsvc/${version}/ild/usage/`,
  shopWareAddToCart: `/cart/plan`,
  shopWareUpdateCart: `/store-api/checkout/cart/line-item`,
  shopWareUpdateShippingMethods: `/store-api/checkout/cart/line-item`,
  shopWareGetCart: `/store-api/checkout/cart`,
  shopWareEye4fraud: `/orders/plan/pending`,
  shopWareGetCountries: `/store-api/country`,
  shopWareAuth: `/auth`,
  shopWareDeleteCart: `/store-api/checkout/cart/line-item?ids[]`,
  shopWareCreateAddress: `/store-api/account/address`,
  shopWareDefaultAddressOnShopware: `/store-api/account/address/default-shipping/`,
  shopWareUpdateAddress: `/store-api/account/address/`,
  shopWareEmailUpdate: `/store-api/account/change-email`,
  shopWareGetCustomer: `store-api/account/customer`,
  shopWareGetAddress: `/store-api/account/list-address`,
  liveOpsMessages: `/msgsvc/${version}/search`,
  verifyRecoveryEmail: `custmgmntsvc/${version}/security/action`,
  generateOtp: `custmgmntsvc/${version}/security/action`,
  verifyOtp: `custmgmntsvc/${version}/verify/security/action`,
  sendAccountLockReminder: `custmgmntsvc/${version}/send/account/lock/reminder/`,
  sendAPNSettingsPostPurchase: `/custsvc/${version}/ios/att/apn/guidelines/email/send`,
  sendAPNSettingsBeforePurchase: `/custsvc/${version}/ios/att/apn/guidelines/send/email`,
  sendAPNSettingsAndroidPostPurchase: `/custsvc/${version}android/att/apn/guidelines/email/send/`,
  sendAPNSettingsAndroidBeforePurchase: `/custsvc/${version}/android/att/apn/guidelines/send/email`,
};

export default ApiEndPoints;