import axios from "./axios";
import setAuthToken from "./auth";
import { v4 as uuidv4 } from "uuid";
import axiosStrapi from "./axiosStrapi";
import axiosShopWare from "./axiosShopWare";
import axiosShopWareCustom from "./axiosShopwareCustom";
import EnvConfig from "../config/env.config.json";
import getAnnonymousUser from "./getAnnonymousUser";
import Cookies from "js-cookie";
type Params = {
  method: string;
  url: string;
  body?: any;
  token?: string;
  params?: any;
  headers?: any;
  auth?: boolean;
  other?: boolean;
  other1?: string;
  contentType?: string;
  isStrapiApi?: boolean;
  tenantId?: any;
  isShopWare?: boolean;
  domain?: string;
  isBearerTokenRequired?: boolean;
};
class ApiJunction {
  async makeRequest(params: Params) {
    const resToken = await setAuthToken();
    if (params.isStrapiApi) {
      return axiosStrapi.get(params.url, { params: params.params });
    }
    let token = resToken;
    if (!token) {
      token = await getAnnonymousUser();
    }
    if (params.isShopWare) {
      let customAxios: any =
        params?.domain === "shopware" ? axiosShopWare : axiosShopWareCustom;
      let swContextToken = Cookies.get("swContextToken");
      const headers = {
        "sw-access-key": process.env.REACT_APP_SW_ACCESS_KEY,
        ...(swContextToken && { "sw-context-token": swContextToken }),
      };
      if (params.isBearerTokenRequired)
        customAxios.defaults.headers.common["authorization"] = `${token}`;
      if (params.contentType) {
        axios.defaults.headers.post["Content-Type"] = params.contentType;
      } else {
        axios.defaults.headers.common["Content-Type"] = `application/json`;
      }
      if (params.method === "post") {
        return customAxios
          .post(params.url, params.body, {
            headers: headers,
          })
          .then((res: any): any => {
            return res;
          })
          .then((err: any) => {
            return err;
          });
      } else if (params.method === "get") {
        return customAxios.get(params.url, { headers: headers });
      } else if (params.method === "patch") {
        return customAxios.patch(params.url, params.body, {
          headers: headers,
        });
      } else if (params.method === "delete") {
        return customAxios.delete(params.url, {
          headers: headers,
        });
      }
    }
    axios.defaults.headers.common["txnid"] = uuidv4();
    axios.defaults.headers.common["x-reach-mvne"] = EnvConfig.OPERATOR;
    // if(EnvConfig?.IS_SSO_LOGIN) {
    //   let ssoToken = localStorage.getItem('ssoAccessToken');
    //   axios.defaults.headers.common['authorization'] = `Bearer ${true}|${ssoToken}`;
    // } else {
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
    // }
    // axios.defaults.headers.common['authorization'] = EnvConfig.IS_DEV_ENV ? 'fefc0fcf-5fa4-4966-9db1-fd7896c4b3e6' : '2e656744-dc7e-4d8a-bdec-6df49de51bb2';
    axios.defaults.headers.common[
      params.auth ? "x-account-src" : "x-reach-src"
    ] = params.auth ? `reach` : EnvConfig.X_REACH_SRC;
    if (params.contentType) {
      axios.defaults.headers.post["Content-Type"] = params.contentType;
    } else {
      axios.defaults.headers.common["Content-Type"] = `application/json`;
    }
    if (params.other) {
      axios.defaults.headers.common["x-is-primary"] = params.other
        ? "Yes"
        : "No";
    }
    if (params.other1) {
      axios.defaults.headers.common["x-reach-mvne"] = params.other1
        ? params.other1
        : "";
    }
    if (EnvConfig.TENANT_ID) {
      axios.defaults.headers.common["x-partner-tenant-id"] =
        EnvConfig.TENANT_ID;
    }

    if (params.method === "get") {
      return axios.get(params.url, { params: params.params });
    } else if (params.method === "post") {
      return axios
        .post(params.url, params.body)
        .then((res: any): any => {
          return res;
        })
        .then((err) => {
          return err;
        });
    } else if (params.method === "put") {
      return axios.put(params.url, params.body);
    } else if (params.method === "delete") {
      return axios.delete(params.url, params.body);
    } else if (params.method === "patch") {
      return axios.patch(params.url, params.body, params.headers);
    } else {
      return { success: false, msg: "No method provided, get, post?" };
    }
  }

  login(params: Params) {
    return axios.post(params.url, params.body);
  }
}

export default new ApiJunction();
